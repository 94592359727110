<template>
  <!-- ON LARGE AND MEDIUM SCREENS ONLY -->
  <div id="banner_content">
    <v-container>
      <!-- MEDIUM AND LARGE SCREENS -->
      <!-- <v-row class="d-flex flex-row transparent-banner desk-banner">
        <v-col cols="5" class="d-flex flex-column justify-start">
          <img src="../images/hss/logos/new_logo.jpg" alt="High Sierra Logo" />
        </v-col>
        <v-col cols="2"
          ><img
            src="../images/hss/logos/hss.png"
            alt="High Sierra Logo"
        /></v-col>

        <v-col
          cols="5"
          class="d-flex flex-column flex-wrap justify-end align-content-end"
        >
          <h3 class="text-right">775.437.0043</h3>
          <v-btn color="accent" class="mail-btn">
            <a href="mailto:info@highsierrasecurity.com" target="_blank"
              >Contact Us</a
            >
          </v-btn>
        </v-col>
      </v-row> -->

      <!-- SMALL SCREENS -->
      <!--   -->

      <!-- <v-row class="mt-n9 hide-on-large hide-on-medium">
        <v-col
          cols="12"
          class="d-flex justify-center align-start transparent-banner"
        >
          <img src="../images/gold-badge-320.png" alt="RPD gold badge" />
        </v-col>

        <v-col
          cols="12"
          sm="12"
          class="d-flex flex-row flex-nowrap justify-space-between align-center pa-0"
        >
          <v-btn color="accent" class="pl-2 pr-2 ph-btn">775.437.0043</v-btn>
          <v-btn color="accent" class="pl-2 pr-2 mail-btn">
            <a class="alice" href="mailto:RECRUITER@RENO.GOV" target="_blank"
              >Contact a recruiter</a
            >
          </v-btn>
        </v-col>
      </v-row> -->

      <!-- TOOLBAR -->
      <!-- justify-center -->
      <!-- HIDE ON SMALL -->
      <v-toolbar
        cols="12"
        dense
        flat
        class="desktop-toolbar d-flex flex-row justify-center hide-on-small mt-2"
      >
        <v-hover v-slot="{ hover }">
          <v-btn to="/" text :style="{ color: hover ? '#FFE082' : 'aliceblue' }"
            >Home</v-btn
          >
        </v-hover>
        <v-spacer></v-spacer>

        <v-hover v-slot="{ hover }"
          ><v-btn
            to="/position1"
            text
            :style="{ color: hover ? '#FFE082' : 'aliceblue' }"
            >Recruit</v-btn
          ></v-hover
        >

        <v-spacer></v-spacer>
        <v-hover v-slot="{ hover }"
          ><v-btn
            to="/position3"
            text
            :style="{ color: hover ? '#FFE082' : 'aliceblue' }"
            >Professional</v-btn
          ></v-hover
        >
        <v-spacer></v-spacer>
        <v-hover v-slot="{ hover }"
          ><v-btn
            v-on:click="message2"
            text
            :style="{ color: hover ? '#FFE082' : 'aliceblue' }"
            >Events</v-btn
          ></v-hover
        >
        <v-spacer></v-spacer>
        <v-hover v-slot="{ hover }"
          ><v-btn
            v-on:click="message2"
            text
            :style="{ color: hover ? '#FFE082' : 'aliceblue' }"
            >About</v-btn
          ></v-hover
        >
        <v-spacer></v-spacer>
        <v-hover v-slot="{ hover }" class="hide-on-medium"
          ><v-btn
            v-on:click="message2"
            text
            :style="{ color: hover ? '#FFE082' : 'aliceblue' }"
            >Contact</v-btn
          ></v-hover
        >
        <v-spacer></v-spacer>
      </v-toolbar>

      <!-- NAV DRAWER -->
      <!-- HIDE ON MED AND LARGE -->
      <v-card
        flat
        class="mx-auto overflow-hidden mob-nav hide-on-medium hide-on-large"
        height="400"
        style="
          background-color: transparent;
          margin-top: -170px;
          margin-left: -12px !important;
        "
      >
        <v-app-bar color="#333" style="width: 50px">
          <v-app-bar-nav-icon
            @click="drawer = true"
            class="alice"
          ></v-app-bar-nav-icon>
        </v-app-bar>

        <v-navigation-drawer
          v-model="drawer"
          relative
          temporary
          hide-overlay
          class="mob-nav-drawer mt4"
          style="width: 40%; background-color: #344e6b; height: 59%"
        >
          <v-list nav dense>
            <!-- active-class="accent" -->
            <v-list-item-group v-model="group">
              <v-list-item>
                <v-btn
                  to="/"
                  text
                  flat
                  class="alice"
                  style="background-color: transparent"
                  >Home</v-btn
                >
              </v-list-item>

              <v-list-item>
                <v-btn
                  to="/position1"
                  text
                  flat
                  class="alice"
                  style="background-color: transparent"
                  >Recruit</v-btn
                >
              </v-list-item>

              <v-list-item>
                <v-btn
                  to="/position3"
                  text
                  flat
                  class="alice"
                  style="background-color: transparent"
                  >Professional</v-btn
                >
              </v-list-item>

              <v-list-item>
                <v-btn
                  v-on:click="message2"
                  text
                  flat
                  class="alice"
                  style="background-color: transparent"
                  >Events</v-btn
                >
              </v-list-item>
            </v-list-item-group>
          </v-list>
        </v-navigation-drawer>
      </v-card>
    </v-container>
  </div>
</template>

<style lang="scss" scoped>
@import "../scss/site.scss";
/* 0 media q */
@media (min-width: 0px) {
  .v-overlay__scrim {
    opacity: unset;
    background-color: unset;
    border: none;
  }
  #banner_content {
    color: white;
    height: 175px;
  }

  .transparent-banner img {
    width: 125px;
    filter: drop-shadow(0.25rem 0.25rem 0.5rem #000000);
  }

  .transparent-banner .mail-btn {
    width: 40%;
  }

  .alice {
    color: aliceblue;
  }

  .mob-nav .alice {
    color: aliceblue;
  }

  .v-navigation-drawer {
    background-color: #344e6b;
  }

  .ph-btn,
  .mail-btn {
    width: 42%;
  }

  .sm-toolbar v-toolbar__content {
    flex-direction: comlumn !important;
  }

  .v-size--default {
    font-size: 0.65rem;
  }

  .transparent-banner {
    background-color: transparent;
  }

  .theme--light.v-toolbar.v-sheet {
    background-color: transparent;
  }
}

/* 768 media q */
@media (min-width: 768px) {
  .desk-banner h3 {
    text-shadow: 2px 2px 4px #000000;
  }
  #banner_content {
    height: 190px;
  }
  .transparent-banner {
    background-color: transparent;
  }
  .transparent-banner img {
    width: 155px;
  }

  .transparent-banner .mail-btn {
    width: 70%;
  }

  .v-size--default {
    font-size: 0.85rem;
  }

  .v-toolbar {
    width: 80%;
  }
  .desktop-toolbar {
    margin: auto;
    width: 70%;
  }

  .v-toolbar__content {
    width: 82%;
  }

  .desktop-toolbar .v-btn {
    color: aliceblue;
    text-shadow: 2px 2px 4px #000000;
    font-size: 1rem;
  }

  .desktop-toolbar .v-btn .v-size--default {
    font-size: 1.25rem;
  }
}

/* 1025 media q */
@media (min-width: 1025px) {
  .transparent-banner .mail-btn {
    width: 36%;
  }

  #banner_content {
    height: 135px;
  }

  .desktop-toolbar {
    width: 100%;
  }

  .desktop-toolbar .spacer {
    width: 75px;
  }
}

/* 2048 media q */
@media (min-width: 2048px) {
}
</style>

<script>
/* eslint-disable */
import { bus_common } from "../mixins/bus_common";
export default {
  name: "banner",
  mixins: [bus_common],
  data() {
    return {
      drawer: false,
      group: null,
    };
  },
  methods: {
    message1() {
      alert("This button does nothing yet.");
    },
    message2() {
      alert("This button will take the user to the appropriate page.");
    },
    init() {},
  },
  mounted() {
    this.$nextTick(() => {
      this.init();
    });
  },
};
</script>
